import './index.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import Dashboard from './components/Dashboard';
import CaseList from './components/CaseList';
import ClientForm from './components/ClientForm';
import CaseForm from './components/CaseForm';
import RegisterForm from './components/RegisterForm';
import Navbar from './components/Navbar';
import authService from './services/authService';  // Authentication service
import caseService from './services/caseService';  // Case service
import clientService from './services/clientService';
import PrivateRoute from './components/PrivateRoute';  // PrivateRoute component to protect routes


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [clients, setClients] = useState([]);  // Needed for client filtering
  const [filteredClients, setFilteredClients] = useState([]);  // Needed for client filtering
  const [showSearch, setShowSearch] = useState(false); // For search visibility
  const [searchQuery, setSearchQuery] = useState('');  // Track the search input



  const fetchClients = async () => {
    try {
      const clientsList = await clientService.getClients();
      setClients(clientsList);
      setFilteredClients(clientsList);  // Initially, show all clients
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const checkAuth = () => {
    // Check if the user is logged in by validating the token
    const token = authService.getToken();
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    setLoading(false); // Set loading to false after checking authentication
  };

  useEffect(() => {
    checkAuth();
    fetchClients();
  }, []);

  useEffect(() => {
    if (clients.length > 0 || searchQuery) {
      handleSearch();  // Ensure handleSearch is only called when clients or searchQuery is available
    }
  }, [clients, searchQuery]);

  if (loading) {
    return <div>Loading...</div>; // Display a loading message while authentication is checked
  }

  // View All Clients - Reset the filter
  const handleViewAllClients = () => {
    setFilteredClients(clients); // Reset to all clients
  };

  // View Today's Cases - Filter clients with cases scheduled today
  const handleViewTodaysCases = async () => {
    const today = new Date().toLocaleDateString('en-CA'); // Get today's date in 'YYYY-MM-DD' format
    const filtered = await filterClientsByCaseDate(today, today);
    setFilteredClients(filtered);
  };


  // View This Week's Cases - Filter clients with cases scheduled this week
  const handleViewThisWeeksCases = async () => {
    const today = new Date();  // Ensure today is correctly initialized as the current system date
  
    // Get the day of the week (0 for Sunday, 1 for Monday, etc.)
    const dayOfWeek = today.getDay();
  
    // Calculate Monday's date (start of the week)
    const startOfWeek = new Date(today);
    if (dayOfWeek === 0) { // If today is Sunday (getDay() === 0), adjust to the previous Monday
      startOfWeek.setDate(today.getDate() - 6);
    } else {
      startOfWeek.setDate(today.getDate() - (dayOfWeek - 1));  // Adjust to Monday
    }
  
    // Calculate Friday's date (end of the workweek)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 4);  // Set to Friday
  
    // Convert both dates to 'YYYY-MM-DD' format to avoid time zone issues
    const startDate = startOfWeek.toISOString().split('T')[0];
    const endDate = endOfWeek.toISOString().split('T')[0];
  
    // Add debugging statements to log the values
    console.log('Today:', today.toISOString().split('T')[0]);  // Log today's date
    console.log('Start of Week:', startDate);  // Log start of the week (Monday)
    console.log('End of Week:', endDate);  // Log end of the workweek (Friday)
  
    // Filter clients with cases in this date range
    const filtered = await Promise.all(
      clients.map(async (client) => {
        const clientCases = await caseService.getCases(client._id); // Fetch client cases
        const hasCaseInRange = clientCases.some((caseItem) => {
          const courtDate = caseItem.nextCourtDate
            ? new Date(caseItem.nextCourtDate).toISOString().split('T')[0]
            : null;
  
          return courtDate && courtDate >= startDate && courtDate <= endDate;
        });
        return hasCaseInRange ? client : null;
      })
    );
  
    setFilteredClients(filtered.filter(client => client !== null));  // Remove nulls and update filtered clients
  };




  const filterClientsByCaseDate = async (startDate, endDate) => {
    const filtered = await Promise.all(
      clients.map(async (client) => {
        const clientCases = await caseService.getCases(client._id);
        const hasCaseInRange = clientCases.some((caseItem) => {
          // Ensure both client and filter dates are compared correctly
          const courtDate = new Date(caseItem.nextCourtDate).toISOString().split('T')[0];
          return courtDate >= startDate && courtDate <= endDate;
        });
        return hasCaseInRange ? client : null;
      })
    );
    return filtered.filter(client => client !== null); // Remove nulls
  };


  // Handle Client Search - Filter clients by search query (first name or last name)
  const handleSearch = () => {
    if (searchQuery.trim() === '') {
      setFilteredClients(
        clients.sort((a, b) => {
          if (a.lastName < b.lastName) return -1;
          if (a.lastName > b.lastName) return 1;
          if (a.firstName < b.firstName) return -1;
          if (a.firstName > b.firstName) return 1;
          return 0;
        })
      );  // Reset to show all clients if search is empty
    } else {
      const filtered = clients.filter(client =>
        client.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        client.lastName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredClients(
        filtered.sort((a, b) => {
          if (a.lastName < b.lastName) return -1;
          if (a.lastName > b.lastName) return 1;
          if (a.firstName < b.firstName) return -1;
          if (a.firstName > b.firstName) return 1;
          return 0;
        })
      );
    }
  };


  const handleCaseSubmit = async (caseData) => {
    try {
      if (caseData.id) {
        // If there's an ID, it's an update
        await caseService.updateCase(caseData.id, caseData);
      } else {
        // Otherwise, it's a new case
        await caseService.createCase(caseData);
      }
    } catch (error) {
      console.error('Error submitting case data:', error);
    }
  };


  return (
    <Router>
      <Navbar
        isAuthenticated={isAuthenticated}
        handleViewAllClients={handleViewAllClients}
        handleViewTodaysCases={handleViewTodaysCases}
        handleViewThisWeeksCases={handleViewThisWeeksCases}
        setShowSearch={setShowSearch}
        showSearch={showSearch}  // Pass showSearch prop
        searchQuery={searchQuery}  // Pass searchQuery prop
        setSearchQuery={setSearchQuery}
      />

      <Routes>
        {/* Redirect root `/` based on authentication status */}
        <Route
          path="/"
          element={isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/login" />}
        />

        {/* Public Routes */}
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />

        {/* Private Routes */}
        <Route
          path="/dashboard"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <Dashboard
                filteredClients={filteredClients}
                showSearch={showSearch}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}  // Pass setSearchQuery to Dashboard
                setFilteredClients={setFilteredClients}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/cases"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <CaseList />
            </PrivateRoute>
          }
        />
        <Route
          path="/client-form"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <ClientForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/case-form/new"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <CaseForm onSubmit={handleCaseSubmit} />
            </PrivateRoute>
          }
        />
        <Route
          path="/case-form/:id"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <CaseForm onSubmit={handleCaseSubmit} />
            </PrivateRoute>
          }
        />

        {/* Catch-all route - Redirect to /login for unknown paths */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
