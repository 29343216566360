import React, { useState, useEffect } from 'react';
import clientService from '../services/clientService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faPlus, faDownload } from '@fortawesome/free-solid-svg-icons';
import CaseList from './CaseList';  // Import the CaseList component for each client
import InputMask from 'react-input-mask';
import caseService from '../services/caseService';
import axios from 'axios';

const API_URL = 'https://cm-be.vercel.app/api/documents/';

const Dashboard = ({ filteredClients, setFilteredClients }) => {
  const [documentDropdown, setDocumentDropdown] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [clients, setClients] = useState([]);
  const [editingClientId, setEditingClientId] = useState(null);
  const [editedClientData, setEditedClientData] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [expandedClientId, setExpandedClientId] = useState(null);  // To expand/collapse clients
  const [caseData, setCaseData] = useState({});  // To store cases for each client
  const [showAddCaseFormForClientId, setShowAddCaseFormForClientId] = useState(null);  // Added for case form toggling
  const [newCaseData, setNewCaseData] = useState({
    status: '',
    counsel: '',
    caseNumber: '',
    offense: '',
    offenseDate: '',
    nextCourtDate: '',
    notes: '',
  });


  const fetchClients = async () => {
    const clientsList = await clientService.getClients();
    const sortedClients = clientsList.sort((a, b) => {
      if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1;
      if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
      if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
      if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
      return 0;
    });
    setClients(sortedClients); // Set sorted clients
    setFilteredClients(sortedClients); // Set sorted filtered clients
  };

  // Fetch clients from the server on component mount
  useEffect(() => {
    fetchClients();
  }, []);

  const toggleDocumentDropdown = (clientId) => {
    setDocumentDropdown(documentDropdown === clientId ? null : clientId);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadDocument = async (clientId) => {
    if (!selectedFile) {
      alert("Please select a file before uploading.");
      return;
    }
  
    const formData = new FormData();
    formData.append('document', selectedFile);
  
    try {
      await axios.post('https://cm-be.vercel.app/api/documents/upload/client/' + clientId, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Document uploaded successfully!');
  
      // Reset selected file and close the dropdown after successful upload
      setSelectedFile(null);
      setDocumentDropdown(null);
    } catch (error) {
      console.error('Error uploading document:', error);
      alert('Failed to upload document. Please try again.');
    }
  };
  

  // Handle Edit Click
  const handleEditClick = (client) => {
    if (unsavedChanges) {
      const confirm = window.confirm(`You have unsaved changes in ${editedClientData.name}'s file. Please save or cancel those changes before editing another client.`);
      if (!confirm) {
        return;
      }
    }

    setEditingClientId(client._id);
    setEditedClientData(client);
    setUnsavedChanges(false);
  };

  // Handle Input Changes
  const handleInputChange = (e) => {
    setEditedClientData({
      ...editedClientData,
      [e.target.name]: e.target.value,
    });
    setUnsavedChanges(true);
  };

  const handleUpdateClient = async (clientId) => {
    try {
      // Update the client data on the server
      await clientService.updateClient(clientId, editedClientData);

      // Update the client in the local state immediately
      setClients((prevClients) =>
        prevClients.map((client) =>
          client._id === clientId ? { ...client, ...editedClientData } : client
        )
      );

      // Clear edit mode and unsaved changes flag
      setEditingClientId(null);
      setUnsavedChanges(false);

      // Set a timeout to re-fetch clients after a delay (1 seconds)
      setTimeout(() => {
        fetchClients(); // Re-fetch clients to ensure the latest data from the server
      }, 2000);  // Delay of 1 seconds (1000 milliseconds)

    } catch (error) {
      console.error('Error updating client:', error);
    }
  };

  // Handle Cancel Edit
  const handleCancelEdit = () => {
    setEditingClientId(null);
    setUnsavedChanges(false);
  };

  // Handle Add Case Click (toggles the add case form)  // Added for case form toggle
  const handleAddCaseClick = (clientId) => {
    if (showAddCaseFormForClientId === clientId) {
      setShowAddCaseFormForClientId(null);  // Hide the form
    } else {
      setShowAddCaseFormForClientId(clientId);  // Show the form
      setNewCaseData({
        status: '',
        counsel: '',
        caseNumber: '',
        offense: '',
        offenseDate: '',
        nextCourtDate: '',
        notes: '',
      });
    }
  };

  // Handle Add Case Input Changes  // Added to handle case form input
  const handleNewCaseInputChange = (e) => {
    setNewCaseData({
      ...newCaseData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle Save Case (green check click)  // Added to save the case data
  const handleSaveNewCase = async (clientId) => {
    try {
      const caseDataWithClientId = { ...newCaseData, clientId };  // Attach clientId to the case data
      await caseService.createCase(caseDataWithClientId);
      setShowAddCaseFormForClientId(null);  // Hide form after saving
      const updatedCases = await caseService.getCases(clientId);  // Fetch updated cases for the client
      setCaseData((prev) => ({
        ...prev,
        [clientId]: updatedCases,
      }));
    } catch (error) {
      console.error('Error saving new case:', error);
    }
  };

  const handleSaveNotes = async (clientId, caseId, newNotes) => {
    try {
      // Make sure the clientId and caseId are properly passed
      await caseService.updateCaseNotes(caseId, newNotes);
      // Fetch updated cases to reflect the changes
      const updatedCases = await caseService.getCases(clientId);
      setCaseData((prev) => ({
        ...prev,
        [clientId]: updatedCases,  // Update the case data in state
      }));
    } catch (error) {
      console.error('Error updating notes:', error);
    }
  };


  // Handle Cancel Add Case (red X click)  // Added to cancel adding a case
  const handleCancelAddCase = () => {
    setShowAddCaseFormForClientId(null);  // Hide the form without saving
  };

  const toggleClientExpansion = async (clientId) => {
    if (expandedClientId === clientId) {
      setExpandedClientId(null);  // Collapse client case list
    } else {
      const cases = await caseService.getCases(clientId);  // Fetch cases for this client
      setCaseData((prev) => ({
        ...prev,
        [clientId]: cases,
      }));
      setExpandedClientId(clientId);  // Expand the client case list
    }
  };

  const updateCaseDetails = async (caseId, updatedCaseData) => {
    try {
      await caseService.updateCase(caseId, updatedCaseData);  // Make API call to update the case
      const updatedCases = await caseService.getCases(updatedCaseData.clientId);  // Fetch updated cases for the client
      setCaseData((prev) => ({
        ...prev,
        [updatedCaseData.clientId]: updatedCases,  // Update case data in the state
      }));
    } catch (error) {
      console.error('Error updating case details:', error);
    }
  };

  const handleEditCase = (caseItem) => {
    console.log('Edit case', caseItem);
    // Add logic to show a form for editing case information
  };

  // Handle Case Deletion (red trash click)  // Added to handle deleting a case
  const handleDeleteCase = async (caseId, clientId) => {
    try {
      // Call the delete API
      await caseService.deleteCase(caseId);

      // Update the case list in the state to remove the deleted case
      setCaseData((prevCaseData) => {
        const updatedCases = prevCaseData[clientId].filter((caseItem) => caseItem._id !== caseId);
        return { ...prevCaseData, [clientId]: updatedCases };
      });

      console.log('Case deleted successfully.');
    } catch (error) {
      console.error('Error deleting case:', error);
    }
  };


  const formatDateForDisplay = (dateString) => {
    if (!dateString || new Date(dateString).getTime() === 0) {
      return 'N/A';
    }
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];  // Return the date in 'yyyy-mm-dd' format
  };


  const formatDateForInput = (dateString) => {
    if (!dateString || new Date(dateString).getTime() === 0) {
      return '';  // Return an empty string if the date is missing or invalid
    }
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];  // Return the date in 'yyyy-mm-dd' format
  };


  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Client List</h1>

      {filteredClients.length === 0 ? (
        <p className="text-gray-600 text-center">No clients found for the selected filter.</p>
      ) : (
        <ul className="bg-white shadow-md rounded-lg">
          {filteredClients.map(client => (
            <li key={client._id} className="mb-4 bg-gray-100 p-4 rounded-md shadow-md relative w-full" style={{
              paddingBottom: '50px'
            }}>
              {/* Top-right: Edit Icon */}
              <div className="absolute top-2 right-2 space-x-2 flex">
                {/* Edit Button (disabled during edit mode) */}
                <button
                  disabled={editingClientId === client._id}
                  onClick={() => handleEditClick(client)}
                  className={`${editingClientId === client._id ? 'cursor-not-allowed opacity-50' : 'bg-yellow-500 hover:bg-yellow-600'
                    } text-white py-1 px-3 rounded-md`}
                >
                  ✏️
                </button>
                <button
                  onClick={() => toggleDocumentDropdown(client._id)}
                  className="bg-gray-500 hover:bg-gray-600 text-white py-1 px-4 rounded-md"
                >
                  <FontAwesomeIcon icon={faPaperclip} />
                </button>
              </div>

              {/* Document Dropdown (Added for handling document actions) */}
              {documentDropdown === client._id && (
                <div className="mt-2 p-4 bg-gray-100 border border-gray-300 rounded-md">
                  <input type="file" onChange={handleFileChange} />
                  <button onClick={() => handleUploadDocument(client._id)} className="text-green-500 mt-2" disabled={!selectedFile}>
                    <FontAwesomeIcon icon={faPlus} /> Upload
                  </button>
                </div>
              )}

              {/* Bottom-right: Add Case and View Cases Icons */}
              <div className="absolute bottom-2 right-2 space-x-2 flex">
                {editingClientId === client._id ? (
                  <>
                    {/* Green Check Button for Save */}
                    <button
                      onClick={() => handleUpdateClient(client._id)}
                      className="bg-green-500 hover:bg-green-600 text-white py-1 px-4 rounded-md"
                    >
                      ✔️
                    </button>

                    <button
                      onClick={handleCancelEdit}
                      className="bg-red-500 hover:bg-red-600 text-white py-1 px-4 rounded-md"
                    >
                      ❌
                    </button>
                  </>
                ) : (
                  <>
                    {/* Add Case Button */}
                    <button
                      onClick={() => handleAddCaseClick(client._id)}  // Added for Add Case Click
                      className="bg-green-500 hover:bg-green-600 text-white py-1 px-4 rounded-md"
                    >
                      ➕
                    </button>

                    {/* View Cases Icon */}
                    <button
                      onClick={() => toggleClientExpansion(client._id)}
                      className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-4 rounded-md"
                    >
                      📋
                    </button>
                  </>
                )}
              </div>

              {/* Display Client Info */}
              {editingClientId === client._id ? (
                <div className="mt-2 grid grid-cols-2 gap-4 w-full" style={{ paddingRight: "120px" }}>
                  <label>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={editedClientData.firstName || ''}
                    onChange={handleInputChange}
                    className="p-2 border border-gray-300 rounded-md mb-2"
                    required
                  />
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={editedClientData.lastName || ''}
                    onChange={handleInputChange}
                    className="p-2 border border-gray-300 rounded-md mb-2"
                    required
                  />
                  <label>Date of Birth</label>
                  <input
                    type="date"
                    name="dob"
                    value={editedClientData.dob ? new Date(editedClientData.dob).toISOString().split('T')[0] : ''}
                    onChange={handleInputChange}
                    className="p-2 border border-gray-300 rounded-md mb-2"
                  />
                  <label>Community</label>
                  <select
                    name="community"
                    value={editedClientData.community || ''}
                    onChange={handleInputChange}
                    className="p-2 border border-gray-300 rounded-md"
                  >
                    <option value="Walpole">Walpole</option>
                    <option value="Caldwell">Caldwell</option>
                    <option value="Moraviantown">Moraviantown</option>
                    <option value="Oneida">Oneida</option>
                    <option value="Munsee">Munsee</option>
                    <option value="Kettle and Stony Point">Kettle and Stony Point</option>
                    <option value="Aamjiwnaang">Aamjiwnaang</option>
                    <option value="Six Nations">Six Nations</option>
                    <option value="Other">Other</option>
                  </select>
                  <p className="text-xs text-gray-600 col-span-2 underline mb-1">
                    If the community is not on the list, select "Other" and update Client Notes.
                  </p>

                  <label>Client Address</label>
                  <input
                    type="text"
                    name="address"
                    value={editedClientData.address || ''}
                    onChange={handleInputChange}
                    className="p-2 border border-gray-300 rounded-md mb-2"
                  />
                  <label>Client Phone Number</label>
                  <InputMask
                    name="phone"
                    value={editedClientData.phone || ''}
                    onChange={handleInputChange}
                    className="p-2 border border-gray-300 rounded-md mb-2"
                    mask="(999)999-9999"
                  />
                  <label>Client Email</label>
                  <input
                    type="email"
                    name="email"
                    value={editedClientData.email || ''}
                    onChange={handleInputChange}
                    className="p-2 border border-gray-300 rounded-md mb-2"
                  />
                  <label>Preferred Method of Contact</label>
                  <select
                    name="preferredContact"
                    value={editedClientData.preferredContact || ''}
                    onChange={handleInputChange}
                    className="p-2 border border-gray-300 rounded-md mb-2"
                  >
                    <option value="None">None</option>
                    <option value="Email">Email</option>
                    <option value="Text">Text</option>
                  </select>
                  <label>Client Notes</label>
                  <textarea
                    name="notes"
                    value={editedClientData.notes || ''}
                    onChange={handleInputChange}
                    className="p-2 border border-gray-300 rounded-md mb-2"
                  />
                </div>
              ) : (
                <div className="mt-2">
                  <p className="text-3xl font-bold mb-2">{`${client.lastName}, ${client.firstName}`}</p>
                  <p className="text-sm text-gray-600">Date of Birth: {client.dob ? new Date(client.dob).toISOString().split('T')[0] : 'N/A'}</p>
                  {client.community && <p className="text-sm text-gray-600">Community: {client.community}</p>}
                  {client.address && <p className="text-sm text-gray-600">Address: {client.address}</p>}
                  {client.phone && <p className="text-sm text-gray-600">Phone: {client.phone}</p>}
                  {client.email && <p className="text-sm text-gray-600">Email: {client.email}</p>}
                  {client.preferredContact && <p className="text-sm text-gray-600">Preferred Contact: {client.preferredContact}</p>}
                  {client.notes && <p className="text-sm text-gray-600">Notes: {client.notes}</p>}
                </div>
              )}

              {/* Add Case Form */}
              {showAddCaseFormForClientId === client._id ? (
                <div className="mt-4 p-4 bg-gray-100 rounded-md w-full">
                  <div className="grid grid-cols-2 gap-4 w-full">
                    <div>
                      <label>Current Status</label>
                      <select
                        name="status"
                        value={newCaseData.status}
                        onChange={handleNewCaseInputChange}
                        className="p-2 border border-gray-300 rounded-md w-full"
                      >
                        <option value="" disabled hidden>Select Status</option>
                        <option value="First Appearance">First Appearance</option>
                        <option value="Disclosure">Disclosure</option>
                        <option value="Awaiting Client Instruction">Awaiting Client Instruction</option>
                        <option value="Crown Pre-Trial">Crown Pre-Trial</option>
                        <option value="Self-Rep Judicial Pre-Trial">Self-Rep Judicial Pre-Trial</option>
                        <option value="Judicial Pre-Trial">Judicial Pre-Trial</option>
                        <option value="Plea">Plea</option>
                        <option value="Sentencing">Sentencing</option>
                        <option value="Diversion">Diversion</option>
                        <option value="Resolved">Resolved</option>
                      </select>
                    </div>
                    <div>
                      <label>Counsel</label>
                      <input
                        type="text"
                        name="counsel"
                        value={newCaseData.counsel}
                        onChange={handleNewCaseInputChange}
                        className="p-2 border border-gray-300 rounded-md w-full"
                      />
                    </div>
                    <div>
                      <label>Case Number</label>
                      <input
                        type="text"
                        name="caseNumber"
                        value={newCaseData.caseNumber}
                        onChange={handleNewCaseInputChange}
                        className="p-2 border border-gray-300 rounded-md w-full"
                      />
                    </div>
                    <div>
                      <label>Offense</label>
                      <input
                        type="text"
                        name="offense"
                        value={newCaseData.offense}
                        onChange={handleNewCaseInputChange}
                        className="p-2 border border-gray-300 rounded-md w-full"
                      />
                    </div>
                    <div>
                      <label>Offense Date</label>
                      <input
                        type="date"
                        name="offenseDate"
                        value={formatDateForInput(newCaseData.offenseDate)}  // Ensure correct input format
                        onChange={handleNewCaseInputChange}
                        className="p-2 border border-gray-300 rounded-md w-full"
                      />
                    </div>
                    <div>
                      <label>Next Court Date</label>
                      <input
                        type="date"
                        name="nextCourtDate"
                        value={formatDateForInput(newCaseData.nextCourtDate)}  // Ensure correct input format
                        onChange={handleNewCaseInputChange}
                        className="p-2 border border-gray-300 rounded-md w-full"
                      />
                    </div>
                    <div>
                      <label>Notes</label>
                      <textarea
                        name="notes"
                        value={newCaseData.notes}
                        onChange={handleNewCaseInputChange}
                        className="p-2 border border-gray-300 rounded-md w-full"
                      />
                    </div>
                  </div>

                  {/* Green Check & Red X */}
                  <div className="absolute bottom-2 right-2 space-x-2 flex">
                    <button
                      onClick={() => handleSaveNewCase(client._id)}
                      className="bg-green-500 hover:bg-green-600 text-white py-1 px-4 rounded-md"
                    >
                      ✔️
                    </button>
                    <button
                      onClick={handleCancelAddCase}
                      className="bg-red-500 hover:bg-red-600 text-white py-1 px-4 rounded-md"
                    >
                      ❌
                    </button>
                  </div>
                </div>
              ) : null}

              {/* Show CaseList if client is expanded */}
              {expandedClientId === client._id && (
                <CaseList
                  cases={caseData[client._id] || []}
                  onEdit={handleEditCase}
                  onDelete={(caseId) => handleDeleteCase(caseId, client._id)}
                  onSaveNotes={handleSaveNotes}
                  onSaveCase={updateCaseDetails}
                  clientId={client._id}
                />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dashboard;