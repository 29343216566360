// authService.js

// Register the user
const register = async (userData) => {
  const response = await fetch('/api/auth/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  });

  const data = await response.json();

  if (response.ok) {
    localStorage.setItem('user', JSON.stringify(data));
  }

  return data;
};

// Login the user (without JWT)
const login = async (userData) => {
  const response = await fetch('https://cm-be.vercel.app/api/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  });

  const data = await response.json();
  if (response.ok) {
    localStorage.setItem('user', JSON.stringify(data.user));
    localStorage.setItem('isAuthenticated', 'true');  // Set isAuthenticated to true
    window.location.href = '/dashboard'; // <-- Add this line
    return data.user;
  } else {
    throw new Error(data.message);
  }
};

// Logout the user
const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('isAuthenticated');
  window.location.href = '/login';  // Redirect to the login page after logout
};

// Get authentication token or user data
const getToken = () => {
  return localStorage.getItem('user'); // Returns the stored user object or token
};

const authService = {
  register,
  login,
  logout,
  getToken,  // Export the getToken function
};

export default authService;
