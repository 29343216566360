const getClients = async () => {
  const response = await fetch('https://cm-be.vercel.app/api/clients');
  const data = await response.json();
  return data;
};

const getCaseStatistics = async () => {
  const response = await fetch('https://cm-be.vercel.app/api/cases/statistics');
  const data = await response.json();
  return data;
};

const createClient = async (clientData) => {
  const response = await fetch('https://cm-be.vercel.app/api/clients', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(clientData),
  });

  if (!response.ok) {
    throw new Error('Failed to create client');
  }

  return await response.json();
};

const updateClient = async (clientId, updatedData) => {
  const response = await fetch(`https://cm-be.vercel.app/api/clients/${clientId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedData),
  });

  if (!response.ok) {
    throw new Error('Failed to update client');
  }

  return await response.json();
};

const clientService = {
  getClients,
  getCaseStatistics,
  createClient,
  updateClient,
};

export default clientService;
