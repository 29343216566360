import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPen, faTrash, faStickyNote, faCheck, faTimes, faPaperclip, faPlus, faDownload
} from '@fortawesome/free-solid-svg-icons';

const CaseList = ({ cases = [], onDelete, onSaveCase, onSaveNotes, clientId }) => {
    const [newNotes, setNewNotes] = useState('');
    const [editingCaseId, setEditingCaseId] = useState(null);
    const [editingNotesCaseId, setEditingNotesCaseId] = useState(null);
    const [editedCaseData, setEditedCaseData] = useState({});
    const [documentDropdown, setDocumentDropdown] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);

    const toggleDocumentDropdown = (clientId) => {
        setDocumentDropdown((prev) => ({
          ...prev,
          [clientId]: !prev[clientId],
        }));
      };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleUploadDocument = async (caseId) => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('document', selectedFile);

    try {
        await documentService.uploadDocument(clientId, formData);
      alert('Document uploaded successfully!');
      setSelectedFile(null);
      setDocumentDropdown(null);
    } catch (error) {
      console.error('Error uploading document:', error);
    }
  };

    const formatDateForDisplay = (dateString) => {
        if (!dateString || new Date(dateString).getTime() === 0) {
            return 'N/A';
        }
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];  // Format as 'YYYY-MM-DD'
    };

    useEffect(() => {
        const textarea = document.querySelector('textarea');
        if (textarea) {
            autoResize({ target: textarea });
        }
    }, [newNotes]); 

    const formatNotes = (notes) => {
        return notes.split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ));
    };

    const autoResize = (e) => {
        e.target.style.height = 'auto';  
        e.target.style.height = e.target.scrollHeight + 'px';  
    };

    const formatDateForInput = (dateString) => {
        if (!dateString || new Date(dateString).getTime() === 0) {
            return '';  
        }
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];  
    };

    const handleEditNotesClick = (caseItem) => {
        setEditingNotesCaseId(caseItem._id);
        setNewNotes(caseItem.notes || '');  
    };

    const handleCancelNotesClick = () => {
        setEditingNotesCaseId(null);  
        setNewNotes('');  
    };

    const handleSaveNotesClick = async (caseId) => {
        try {
            await onSaveNotes(clientId, caseId, newNotes);
            setEditingNotesCaseId(null);  
            setNewNotes('');  
        } catch (error) {
            console.error('Error saving notes:', error);
        }
    };

    const handleEditClick = (caseItem) => {
        setEditingCaseId(caseItem._id);
        setEditedCaseData(caseItem);
    };

    const handleEditInputChange = (e) => {
        setEditedCaseData({
            ...editedCaseData,
            [e.target.name]: e.target.value
        });
    };

    const handleSaveCaseClick = async (caseId) => {
        const updatedCaseData = {
            ...editedCaseData,
            offenseDate: editedCaseData.offenseDate ? editedCaseData.offenseDate : null,  
            nextCourtDate: editedCaseData.nextCourtDate ? editedCaseData.nextCourtDate : null,  
        };

        try {
            await onSaveCase(caseId, updatedCaseData);  
            setEditingCaseId(null);
        } catch (error) {
            console.error('Error updating case:', error);
        }
    };

    const handleCancelEditClick = () => {
        setEditingCaseId(null);
    };

    return (
        <div className="mt-4 p-4 bg-white shadow-lg rounded-lg w-full">
            <h3 className="text-lg font-semibold mb-4">Cases</h3>
            <ul className="space-y-4">
                {
                    cases.map((caseItem) => (
                        <li key={caseItem._id} className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                            <div>
                                {editingCaseId === caseItem._id ? (
                                    <div className="space-y-4">
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div>
                                                <label>Status</label>
                                                <select
                                                    name="status"
                                                    value={editedCaseData.status}
                                                    onChange={handleEditInputChange}
                                                    className="p-2 border border-gray-300 rounded-md w-full"
                                                >
                                                    <option value="First Appearance">First Appearance</option>
                                                    <option value="Disclosure">Disclosure</option>
                                                    <option value="Awaiting Client Instruction">Awaiting Client Instruction</option>
                                                    <option value="Crown Pre-Trial">Crown Pre-Trial</option>
                                                    <option value="Self-Rep Judicial Pre-Trial">Self-Rep Judicial Pre-Trial</option>
                                                    <option value="Judicial Pre-Trial">Judicial Pre-Trial</option>
                                                    <option value="Plea">Plea</option>
                                                    <option value="Sentencing">Sentencing</option>
                                                    <option value="Diversion">Diversion</option>
                                                    <option value="Resolved">Resolved</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label>Counsel</label>
                                                <input
                                                    name="counsel"
                                                    value={editedCaseData.counsel}
                                                    onChange={handleEditInputChange}
                                                    className="p-2 border border-gray-300 rounded-md w-full"
                                                />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div>
                                                <label>Case Number</label>
                                                <input
                                                    name="caseNumber"
                                                    value={editedCaseData.caseNumber}
                                                    onChange={handleEditInputChange}
                                                    className="p-2 border border-gray-300 rounded-md w-full"
                                                />
                                            </div>
                                            <div>
                                                <label>Offense</label>
                                                <input
                                                    name="offense"
                                                    value={editedCaseData.offense}
                                                    onChange={handleEditInputChange}
                                                    className="p-2 border border-gray-300 rounded-md w-full"
                                                />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div>
                                                <label>Offense Date</label>
                                                <input
                                                    type="date"
                                                    name="offenseDate"
                                                    value={formatDateForInput(editedCaseData.offenseDate)}
                                                    onChange={handleEditInputChange}
                                                    className="p-2 border border-gray-300 rounded-md w-full"
                                                />
                                            </div>
                                            <div>
                                                <label>Next Court Date</label>
                                                <input
                                                    type="date"
                                                    name="nextCourtDate"
                                                    value={formatDateForInput(editedCaseData.nextCourtDate)}
                                                    onChange={handleEditInputChange}
                                                    className="p-2 border border-gray-300 rounded-md w-full"
                                                />
                                            </div>
                                        </div>

                                        <div className="flex space-x-2">
                                            <button
                                                onClick={() => handleSaveCaseClick(caseItem._id)}
                                                className="bg-green-500 hover:bg-green-600 text-white py-1 px-4 rounded-md"
                                            >
                                                <FontAwesomeIcon icon={faCheck} />
                                            </button>
                                            <button
                                                onClick={handleCancelEditClick}
                                                className="bg-red-500 hover:bg-red-600 text-white py-1 px-4 rounded-md"
                                            >
                                                <FontAwesomeIcon icon={faTimes} />
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <h4 className="text-xl font-bold mb-2">Status: {caseItem.status}</h4>
                                        <p className="text-sm text-gray-600">Counsel: {caseItem.counsel}</p>
                                        <p className="text-sm text-gray-600">Case Number: {caseItem.caseNumber}</p>
                                        <p className="text-sm text-gray-600">Offense: {caseItem.offense}</p>
                                        <p className="text-sm text-gray-600">Offense Date: {formatDateForDisplay(caseItem.offenseDate)}</p>
                                        <p className="text-lg font-medium mb-2">Next Court Date: {formatDateForDisplay(caseItem.nextCourtDate)}</p>
                                        <div className="flex justify-between items-center">
                                            <label className="block text-lg font-semibold text-gray-600">Notes:</label>
                                            <div className="flex space-x-4">
                                                <button
                                                    onClick={() => handleEditClick(caseItem)}
                                                    className="bg-yellow-500 hover:bg-yellow-600 text-white py-1 px-4 rounded-md"
                                                >
                                                    <FontAwesomeIcon icon={faPen} />
                                                </button>
                                                <button
                                                    onClick={() => onDelete(caseItem._id)}
                                                    className="bg-red-500 hover:bg-red-600 text-white py-1 px-4 rounded-md"
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {editingNotesCaseId === caseItem._id ? (
                                    <div>
                                        <textarea
                                            value={newNotes}
                                            onChange={(e) => setNewNotes(e.target.value)}
                                            onInput={autoResize}
                                            className="p-2 border border-gray-300 rounded-md w-full"
                                            rows={2}
                                            style={{ overflow: 'hidden' }}
                                        />
                                        <div className="flex justify-start space-x-2 mt-2">
                                            <button
                                                onClick={() => handleSaveNotesClick(caseItem._id)}
                                                className="bg-green-500 hover:bg-green-600 text-white py-1 px-4 rounded-md"
                                            >
                                                <FontAwesomeIcon icon={faCheck} />
                                            </button>
                                            <button
                                                onClick={handleCancelNotesClick}
                                                className="bg-red-500 hover:bg-red-600 text-white py-1 px-4 rounded-md"
                                            >
                                                <FontAwesomeIcon icon={faTimes} />
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <p className="text-sm text-gray-600">
                                        {caseItem.notes ? formatNotes(caseItem.notes) : 'N/A'}
                                        <button
                                            onClick={() => handleEditNotesClick(caseItem)}
                                            className="bg-gray-500 hover:bg-gray-600 text-white py-1 px-4 rounded-md mt-2 flex justify-start"
                                        >
                                            <FontAwesomeIcon icon={faStickyNote} />
                                        </button>
                                    </p>
                                )}
                            </div>
			<div className="flex items-center space-x-4">
            {/* Document Icon */}
            <button onClick={() => toggleDocumentDropdown(caseItem._id)}>
              <FontAwesomeIcon icon={faPaperclip} />
            </button>
            {documentDropdown === caseItem._id && (
              <div className="dropdown">
                {/* Document List */}
                {caseItem.documents && caseItem.documents.length > 0 ? (
                  caseItem.documents.map((doc) => (
                    <a key={doc._id} href={`/api/documents/download/${doc._id}`} className="block" download>
                      <FontAwesomeIcon icon={faDownload} /> {doc.originalName}
                    </a>
                  ))
                ) : (
                  <p>No documents uploaded.</p>
                )}
                {/* File Upload */}
                <input type="file" onChange={handleFileChange} />
                <button className="text-green-500" onClick={() => handleUploadDocument(caseItem._id)}>
                  <FontAwesomeIcon icon={faPlus} /> Upload
                </button>
              </div>
            )}
          </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
};

export default CaseList;
