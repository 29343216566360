import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import caseService from '../services/caseService';


function CaseForm() {
  const [accusedName, setAccusedName] = useState('');
  const [informationNumber, setInformationNumber] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');
  const [accusedDOB, setAccusedDOB] = useState('');
  const [offense, setOffense] = useState('');
  const [offenseDate, setOffenseDate] = useState('');
  const [currentCourtDate, setCurrentCourtDate] = useState('');
  const [nextCourtDate, setNextCourtDate] = useState('');
  const [notes, setNotes] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      caseService.getCase(id)
        .then((data) => {
          setFormData(data);
        })
        .catch((error) => console.error('Error fetching case data:', error));
    }
  }, [id]);

  const setFormData = (data) => {
    setAccusedName(data.accusedName || '');
    setInformationNumber(data.informationNumber || '');
    setCurrentStatus(data.status || '');
    setAccusedDOB(data.dateOfBirth ? data.dateOfBirth.split('T')[0] : '');
    setOffense(data.offense || '');
    setOffenseDate(data.offenseDate ? data.offenseDate.split('T')[0] : '');
    setCurrentCourtDate(data.currentCourtDate ? data.currentCourtDate.split('T')[0] : '');
    setNextCourtDate(data.nextCourtDate ? data.nextCourtDate.split('T')[0] : '');
    setNotes(data.notes || '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setLoading(true);
      setSuccess('');
      setError('');

      const formData = {
        accusedName,
        informationNumber,
        status: currentStatus,
        dateOfBirth: accusedDOB,
        offense,
        offenseDate,
        currentCourtDate,
        nextCourtDate,
        notes,
      };

      try {
        if (id) {
          await caseService.updateCase(id, formData);
        } else {
          await caseService.createCase(formData);
        }
        setSuccess('Case successfully submitted!');
        setLoading(false);
        setTimeout(() => navigate('/dashboard'), 2000);
      } catch (error) {
        setError('Error submitting form. Please try again.');
        setLoading(false);
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleDateChange = (setter) => (e) => {
    setter(e.target.value);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-8 max-w-lg w-full">
        <h1 className="text-2xl font-bold text-center mb-6 text-gray-800">{id ? 'Update Case' : 'Create Case'}</h1>

        {loading && <div className="text-center text-blue-500 mb-4">Submitting...</div>}
        {success && <div className="text-center text-green-500 mb-4">{success}</div>}
        {error && <div className="text-center text-red-500 mb-4">{error}</div>}

        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Accused Name</label>
          <input
            type="text"
            value={accusedName}
            onChange={(e) => setAccusedName(e.target.value)}
            className={`w-full border border-gray-300 px-4 py-2 rounded-md ${errors.accusedName ? 'border-red-500' : ''}`}
            placeholder="Enter accused name"
          />
          {errors.accusedName && <p className="text-red-500 text-sm mt-1">{errors.accusedName}</p>}
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Information Number</label>
          <input
            type="text"
            value={informationNumber}
            onChange={(e) => setInformationNumber(e.target.value)}
            className={`w-full border border-gray-300 px-4 py-2 rounded-md ${errors.informationNumber ? 'border-red-500' : ''}`}
            placeholder="Enter information number"
          />
          {errors.informationNumber && <p className="text-red-500 text-sm mt-1">{errors.informationNumber}</p>}
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Current Status</label>
          <select
            value={currentStatus}
            onChange={(e) => setCurrentStatus(e.target.value)}
            className={`w-full border border-gray-300 px-4 py-2 rounded-md ${errors.currentStatus ? 'border-red-500' : ''}`}
          >
            <option value="" disabled>Select Status</option>
            <option value="First Appearance">First Appearance</option>
            <option value="Disclosure">Disclosure</option>
            <option value="Awaiting Client Instruction">Awaiting Client Instruction</option>
            <option value="Self-Rep Judicial Pre-Trial">Self-Rep Judicial Pre-Trial</option>
            <option value="Judicial Pre-Trial">Judicial Pre-Trial</option>
            <option value="Plea">Plea</option>
            <option value="Sentencing">Sentencing</option>
            <option value="Diversion">Diversion</option>
            <option value="Resolved">Resolved</option>
          </select>
          {errors.currentStatus && <p className="text-red-500 text-sm mt-1">{errors.currentStatus}</p>}
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Accused DOB</label>
          <input
            type="date"
            value={accusedDOB}
            onChange={handleDateChange(setAccusedDOB)}
            className={`w-full border border-gray-300 px-4 py-2 rounded-md ${errors.accusedDOB ? 'border-red-500' : ''}`}
          />
          {errors.accusedDOB && <p className="text-red-500 text-sm mt-1">{errors.accusedDOB}</p>}
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Offense</label>
          <input
            type="text"
            value={offense}
            onChange={(e) => setOffense(e.target.value)}
            className={`w-full border border-gray-300 px-4 py-2 rounded-md ${errors.offense ? 'border-red-500' : ''}`}
            placeholder="Enter offense"
          />
          {errors.offense && <p className="text-red-500 text-sm mt-1">{errors.offense}</p>}
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Offense Date</label>
          <input
            type="date"
            value={offenseDate ? new Date(offenseDate).toISOString().split('T')[0] : ''}
            onChange={handleDateChange(setOffenseDate)}
            className={`w-full border border-gray-300 px-4 py-2 rounded-md ${errors.offenseDate ? 'border-red-500' : ''}`}
          />
          {errors.offenseDate && <p className="text-red-500 text-sm mt-1">{errors.offenseDate}</p>}
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Current Court Date</label>
          <input
            type="date"
            value={currentCourtDate}
            onChange={handleDateChange(setCurrentCourtDate)}
            className={`w-full border border-gray-300 px-4 py-2 rounded-md ${errors.currentCourtDate ? 'border-red-500' : ''}`}
          />
          {errors.currentCourtDate && <p className="text-red-500 text-sm mt-1">{errors.currentCourtDate}</p>}
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Next Court Date</label>
          <input
            type="date"
            value={nextCourtDate}
            onChange={handleDateChange(setNextCourtDate)}
            className={`w-full border border-gray-300 px-4 py-2 rounded-md ${errors.nextCourtDate ? 'border-red-500' : ''}`}
          />
          {errors.nextCourtDate && <p className="text-red-500 text-sm mt-1">{errors.nextCourtDate}</p>}
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Notes</label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className={`w-full border border-gray-300 px-4 py-2 rounded-md ${errors.notes ? 'border-red-500' : ''}`}
            placeholder="Enter notes"
          />
          {errors.notes && <p className="text-red-500 text-sm mt-1">{errors.notes}</p>}
        </div>

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md w-full"
          disabled={loading}
        >
          {id ? 'Update Case' : 'Create Case'}
        </button>
        <button
          type="button"
          onClick={() => navigate('/dashboard')}
          className="bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded-md w-full mt-4"
        >
          Cancel
        </button>
      </form>
    </div>
  );
}

export default CaseForm;
