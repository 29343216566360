import axios from 'axios';

const API_URL = 'https://cm-be.vercel.app/api/cases/';

// Create a new case
const createCase = async (caseData) => {
  const response = await axios.post(API_URL, caseData);
  return response.data;
};

// Fetch cases for a specific client
const getCases = async (clientId) => {
  const response = await axios.get(`${API_URL}client/${clientId}`);
  return response.data;
};

const deleteCase = async (caseId) => {
  const response = await axios.delete(`${API_URL}${caseId}`);
  return response.data;
};

const updateCase = async (caseId, updatedData) => {
  try {
    console.log('Updating case with ID:', caseId);
    const response = await axios.put(`${API_URL}${caseId}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating case:', error);
  }
};

// Corrected updateCaseNotes function
const updateCaseNotes = async (caseId, newNotes) => {
  const response = await axios.put(`${API_URL}${caseId}/notes`, { notes: newNotes });
  return response.data;
};

// Get Dashboard Data (for statistics)
const getDashboardData = async () => {
  const response = await axios.get('https://cm-be.vercel.app/api/dashboard');
  return response.data;
};

export default {
  createCase,
  deleteCase,
  updateCase,
  getCases,
  getDashboardData,
  updateCaseNotes,  // Ensure this is exported
};
