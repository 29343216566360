import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import authService from '../services/authService';

const Navbar = ({
  isAuthenticated,
  handleViewAllClients,
  handleViewTodaysCases,
  handleViewThisWeeksCases,
  setShowSearch,
  showSearch,
  searchQuery,
  setSearchQuery
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // Toggle for Hamburger menu

  const handleLogout = () => {
    authService.logout();
    window.location.href = '/login';
  };

  const returnToDashboard = () => {
    navigate('/dashboard');
  };

  const isDashboardPage = location.pathname === '/dashboard';
  const hideReturnToDashboard = location.pathname === '/login' || location.pathname === '/register';

  // Toggle Hamburger Menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close menu when a button is clicked
  const handleMenuClick = (action) => {
    action();  // Perform the action (e.g., view clients, view cases)
    setIsOpen(false);  // Close the menu
  };

  return (
    <nav className="bg-gray-800 text-white py-4 px-6 flex justify-between items-center relative">
      {/* Hamburger Menu */}
      {!hideReturnToDashboard && (
        <div className="flex items-center">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      )}

      {/* Search Bar - Right side */}
      {!hideReturnToDashboard && (
        <div className="ml-auto w-full max-w-xs hidden md:block">
          <input
            type="text"
            placeholder="Search clients by name..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="p-2 border border-gray-300 rounded-md w-full text-black"
          />
        </div>
      )}

      {/* Dropdown Menu for Hamburger */}
      <div
        className={`${isOpen ? 'transform translate-x-0' : '-translate-x-full'
          } transition-transform ease-in-out duration-500 absolute top-full left-0 bg-gray-800 w-full md:w-1/4 min-h-min pb-2 z-10`}
      >
        {isDashboardPage && !hideReturnToDashboard ? (
          <>
            <button
              className="block w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md text-center mt-4"
              onClick={() => handleMenuClick(() => navigate('/client-form'))}
            >
              Add Client
            </button>
            <button
              className="block w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md text-center mt-4"
              onClick={() => handleMenuClick(handleViewAllClients)}
            >
              View All Clients
            </button>
            <button
              className="block w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md text-center mt-4"
              onClick={() => handleMenuClick(handleViewTodaysCases)}
            >
              View Today's Cases
            </button>
            <button
              className="block w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md text-center mt-4"
              onClick={() => handleMenuClick(handleViewThisWeeksCases)}
            >
              View This Week's Cases
            </button>

            {/* Spacer to push logout to the bottom */}
            <div className="flex-grow mb-4"></div>

            {/* Logout button at the bottom */}
            {isAuthenticated && (
              <button
                onClick={() => handleMenuClick(handleLogout)}
                className="block w-full bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md mt-auto mb-4"
              >
                Logout
              </button>
            )}
          </>
        ) : (
          !hideReturnToDashboard && (
            <button
              className="block w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md text-center"
              onClick={() => handleMenuClick(returnToDashboard)}
            >
              Return to Dashboard
            </button>
          )
        )}
      </div>

    </nav>
  );
};

export default Navbar;
