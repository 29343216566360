import React, { useState } from 'react';
import clientService from '../services/clientService';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};


const ClientForm = () => {
  const [clientData, setClientData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    community: 'Walpole',
    otherCommunity: '',
    address: '',
    phone: '',
    email: '',
    preferredContact: 'None',
    notes: '',
  });

  const navigate = useNavigate(); // For redirecting to the dashboard

  const handleChange = (e) => {
    setClientData({
      ...clientData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const clientPayload = { ...clientData };

    if (!clientData.email) {
      delete clientPayload.email;
    }
      if (clientData.community === 'Other') {
        clientPayload.community = clientData.otherCommunity;
      }
      await clientService.createClient(clientPayload);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error creating client:', error);
    }
  };

  const handleCancel = () => {
    navigate('/dashboard');
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-8 max-w-lg w-full">
        <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">New Client</h2>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">First Name</label>
          <input
            type="text"
            name="firstName"
            value={clientData.firstName}
            onChange={handleChange}
            className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={clientData.lastName}
            onChange={handleChange}
            className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Date of Birth</label>
          <input
            type="date"
            name="dob"
            value={clientData.dob ? new Date(clientData.dob).toISOString().split('T')[0] : ''}
            onChange={handleChange}
            className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Community</label>
          <select
            name="community"
            value={clientData.community}
            onChange={handleChange}
            className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="Walpole">Walpole</option>
            <option value="Caldwell">Caldwell</option>
            <option value="Moraviantown">Moraviantown</option>
            <option value="Oneida">Oneida</option>
            <option value="Munsee">Munsee</option>
            <option value="Kettle and Stony Point">Kettle and Stony Point</option>
            <option value="Aamjiwnaang">Aamjiwnaang</option>
            <option value="Six Nations">Six Nations</option>
            <option value="Other">Other</option>
          </select>
          <p className="text-sm text-gray-600 mt-1">
            If the community is not on the list select Other and update Client Notes.
          </p>
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Address</label>
          <input
            type="text"
            name="address"
            value={clientData.address}
            onChange={handleChange}
            className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Phone Number</label>
          <InputMask
            mask="(999)999-9999"
            name="phone"
            value={clientData.phone}
            onChange={handleChange}
            className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Email</label>
          <input
            type="email"
            name="email"
            value={clientData.email}
            onChange={handleChange}
            className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Preferred Method of Contact</label>
          <select
            name="preferredContact"
            value={clientData.preferredContact}
            onChange={handleChange}
            className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="None">None</option>
            <option value="Email">Email</option>
            <option value="Text">Text</option>
          </select>
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Client Notes</label>
          <textarea
            name="notes"
            value={clientData.notes}
            onChange={handleChange}
            className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex justify-between">
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            Create Client
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientForm;
